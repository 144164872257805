import React from "react";
import { PageRoute } from "@quest-finance/quest-fe-shared/dist/common/types/PageRoute";

const Accreditation = React.lazy(() => import("../pages/Accreditation"));
const AccreditationSuccess = React.lazy(
  () => import("../pages/AccreditationSuccess")
);

const routes: PageRoute[] = [
  {
    path: "/accreditation/accreditations",
    exact: true,
    component: Accreditation,
  },
  {
    path: "/accreditation/accreditations/success",
    component: AccreditationSuccess,
  },
];

export default routes;
