import { processErrorMessage } from "@quest-finance/quest-fe-shared/dist/error-handler/utils";
import { actionCreator as notifAction } from "@quest-finance/quest-fe-shared/dist/notification";
import { call, ForkEffect, put, takeLatest } from "@redux-saga/core/effects";
import { AxiosError } from "axios";
import {
  submitAccreditationFailed,
  submitAccreditationSuccess,
} from "../actions/creators/accreditationForm";
import {
  SubmitAccreditation,
  SUBMIT_ACCREDITATION,
} from "../actions/types/accreditationForm";
import AccreditationService from "../services/AccreditationService";
import { transformToAccreditationRequest } from "../utils/accreditation";

function* submitAccreditationWorker({
  accreditationForm,
}: SubmitAccreditation) {
  try {
    yield put(notifAction.clearNotification());
    const data = transformToAccreditationRequest(accreditationForm);
    yield call(AccreditationService.submitAccreditation, data);
    yield put(submitAccreditationSuccess());
  } catch (e) {
    const message = processErrorMessage(e as AxiosError);
    yield put(
      notifAction.setNotification({
        id: "SUBMIT_ACCREDITATION_FAILED",
        body: message,
        className: "qst-notif-danger",
      })
    );
    yield put(submitAccreditationFailed());
  }
}

function* watchAccreditationForm(): Generator<
  ForkEffect<never>,
  void,
  unknown
> {
  yield takeLatest(SUBMIT_ACCREDITATION, submitAccreditationWorker);
}

export default watchAccreditationForm;
