import { AccreditationForm } from "../../types/AccreditationForm";
import {
  ResetSubmittedState,
  RESET_SUBMITTED_STATE,
  SubmitAccreditation,
  SubmitAccreditationFailed,
  SubmitAccreditationSuccess,
  SUBMIT_ACCREDITATION,
  SUBMIT_ACCREDITATION_FAILED,
  SUBMIT_ACCREDITATION_SUCCESS,
} from "../types/accreditationForm";

export const submitAccreditation = (
  accreditationForm: AccreditationForm
): SubmitAccreditation => ({
  type: SUBMIT_ACCREDITATION,
  accreditationForm,
});

export const submitAccreditationSuccess = (): SubmitAccreditationSuccess => ({
  type: SUBMIT_ACCREDITATION_SUCCESS,
});

export const submitAccreditationFailed = (): SubmitAccreditationFailed => ({
  type: SUBMIT_ACCREDITATION_FAILED,
});

export const resetSubmittedState = (): ResetSubmittedState => ({
  type: RESET_SUBMITTED_STATE,
});
