import { applicationReducers as application } from "@quest-finance/quest-fe-shared/dist/application";
import { authReducers as auth } from "@quest-finance/quest-fe-shared/dist/auth";
import { errorHandlerReducers as errorHandler } from "@quest-finance/quest-fe-shared/dist/error-handler";
import { reducer as notification } from "@quest-finance/quest-fe-shared/dist/notification";
import { userReducers as user } from "@quest-finance/quest-fe-shared/dist/user";
import { reducer as versionManager } from "@quest-finance/quest-fe-shared/dist/version-manager";
import { combineReducers } from "redux";
import { accreditationReducers as accreditation } from "../accreditation";

export const rootReducer = combineReducers({
  accreditation,
  application,
  errorHandler,
  auth,
  user,
  notification,
  versionManager,
});
