import { stringToDate } from "@quest-finance/quest-fe-shared/dist/common/utils/date";
import { extractProperties } from "@quest-finance/quest-fe-shared/dist/common/utils/object";
import { AccreditationForm } from "../types/AccreditationForm";
import {
  AccreditationRequest,
  accreditationRequestDefaultValue,
} from "../types/AccreditationRequest";

export const transformToAccreditationRequest = (
  accreditationForm: AccreditationForm
): AccreditationRequest => {
  let accreditationRequest = extractProperties(
    accreditationForm,
    Object.keys(accreditationRequestDefaultValue)
  ) as AccreditationRequest;

  for (const field in accreditationRequest) {
    let requestValue =
      accreditationRequest[field as keyof AccreditationRequest];
    if (typeof requestValue !== "boolean" && !requestValue) {
      requestValue = null;
    }
    accreditationRequest = {
      ...accreditationRequest,
      [field]: requestValue,
    };
  }

  accreditationRequest.dateOfBirth = stringToDate(
    accreditationForm.dateOfBirth
  );

  return {
    ...accreditationRequest,
    isLicensedEntityDirector: accreditationRequest.hasAcl
      ? accreditationRequest.isLicensedEntityDirector
      : null,
    businessAddressUnitNumber: accreditationRequest.businessAddressUnitNumber
      ? accreditationRequest.businessAddressUnitNumber
      : "",
    residentialAddressUnitNumber: accreditationRequest.residentialAddressUnitNumber
      ? accreditationRequest.residentialAddressUnitNumber
      : "",
  };
};
