import React from "react";
import { PageRoute } from "@quest-finance/quest-fe-shared/dist/common/types/PageRoute";

const Resources = React.lazy(() => import("../pages/Resources"));
const MatrixAndTraining = React.lazy(
  () => import("../pages/MatrixAndTraining")
);

const routes: PageRoute[] = [
  {
    path: "/resources",
    exact: true,
    component: Resources,
  },
  {
    path: "/policies",
    exact: true,
    component: MatrixAndTraining,
  },
];

export default routes;
