import { ADDRESS_INPUT_TYPES } from "@quest-finance/quest-fe-shared/dist/address-autocomplete";

export interface AccreditationRequest {
  companyName: string;
  companyAbn: string;
  tradingName: string;
  acn: string;
  trustName: string;
  trustAbn: string;
  businessPhoneNumber: string;
  businessNature: string;
  businessAddressInputType: string;
  businessAddressState: string;
  businessAddressStreetName: string;
  businessAddressStreetNumber: string;
  businessAddressUnitNumber: string | null;
  businessAddressSuburb: string;
  businessAddressPostcode: string;
  isAggregator: boolean;
  aggregatorName: string;
  title: string;
  firstName: string;
  middleName: string;
  lastName: string;
  dateOfBirth: Date;
  mobile: string;
  email: string;
  driverLicenseNumber: string;
  driverLicenseState: string;
  residentialAddressInputType: string;
  residentialAddressState: string;
  residentialAddressStreetName: string;
  residentialAddressStreetNumber: string;
  residentialAddressUnitNumber: string | null;
  residentialAddressSuburb: string;
  residentialAddressPostcode: string;
  brokingExperienceDetails: string;
  hasPreviousAccreditation: boolean;
  previousAccreditationDetails: string;
  hasAcl: boolean;
  isLicensedEntityDirector: null | boolean;
  isAclEmployee: boolean;
  isAclRepresentative: boolean;
  aclNumber: string;
  aclRegisteredName: string;
  isPartOfAssociation: boolean;
  associationName: string;
  hasBeenDeclaredBankrupt: boolean;
  hasBeenDisqualified: boolean;
  disqualificationDetails: string;
  hasBeenFoundGuilty: boolean;
  guiltDetails: string;
  hasImpactfulInformation: boolean;
  impactfulInformationDetails: string;
}

export const accreditationRequestDefaultValue: AccreditationRequest = {
  companyName: "",
  companyAbn: "",
  tradingName: "",
  acn: "",
  trustName: "",
  trustAbn: "",
  businessPhoneNumber: "",
  businessNature: "",
  businessAddressInputType: ADDRESS_INPUT_TYPES.AUTOCOMPLETE,
  businessAddressState: "",
  businessAddressStreetName: "",
  businessAddressStreetNumber: "",
  businessAddressUnitNumber: "",
  businessAddressSuburb: "",
  businessAddressPostcode: "",
  isAggregator: false,
  aggregatorName: "",
  title: "",
  firstName: "",
  middleName: "",
  lastName: "",
  dateOfBirth: new Date(),
  mobile: "",
  email: "",
  driverLicenseNumber: "",
  driverLicenseState: "",
  residentialAddressInputType: ADDRESS_INPUT_TYPES.AUTOCOMPLETE,
  residentialAddressState: "",
  residentialAddressStreetName: "",
  residentialAddressStreetNumber: "",
  residentialAddressUnitNumber: "",
  residentialAddressSuburb: "",
  residentialAddressPostcode: "",
  brokingExperienceDetails: "",
  hasPreviousAccreditation: false,
  previousAccreditationDetails: "",
  hasAcl: false,
  isLicensedEntityDirector: null,
  isAclEmployee: false,
  isAclRepresentative: false,
  aclNumber: "",
  aclRegisteredName: "",
  isPartOfAssociation: false,
  associationName: "",
  hasBeenDeclaredBankrupt: false,
  hasBeenDisqualified: false,
  disqualificationDetails: "",
  hasBeenFoundGuilty: false,
  guiltDetails: "",
  hasImpactfulInformation: false,
  impactfulInformationDetails: "",
};
