import { applicationSagas } from "@quest-finance/quest-fe-shared/dist/application";
import { authSagas } from "@quest-finance/quest-fe-shared/dist/auth";
import { errorHandlerSagas } from "@quest-finance/quest-fe-shared/dist/error-handler";
import { userSagas } from "@quest-finance/quest-fe-shared/dist/user";
import { versionManagerSagas } from "@quest-finance/quest-fe-shared/dist/version-manager";
import { all, AllEffect, fork, ForkEffect } from "redux-saga/effects";
import { accreditationSagas } from "../accreditation";

export default function* rootSaga(): Generator<
  AllEffect<ForkEffect<void>>,
  void,
  unknown
> {
  yield all([
    fork(accreditationSagas),
    fork(applicationSagas),
    fork(authSagas),
    fork(userSagas),
    fork(errorHandlerSagas),
    fork(versionManagerSagas),
  ]);
}
