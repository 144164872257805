import { all, AllEffect, fork, ForkEffect } from "@redux-saga/core/effects";
import accreditationForm from "./accreditationForm";

export default function* accreditationSagas(): Generator<
  AllEffect<ForkEffect<void>>,
  void,
  unknown
> {
  yield all([fork(accreditationForm)]);
}
