import { Action } from "redux";
import { AccreditationForm } from "../../types/AccreditationForm";

export const SUBMIT_ACCREDITATION = "SUBMIT_ACCREDITATION";
export interface SubmitAccreditation extends Action {
  type: typeof SUBMIT_ACCREDITATION;
  accreditationForm: AccreditationForm;
}

export const SUBMIT_ACCREDITATION_SUCCESS = "SUBMIT_ACCREDITATION_SUCCESS";
export interface SubmitAccreditationSuccess extends Action {
  type: typeof SUBMIT_ACCREDITATION_SUCCESS;
}

export const SUBMIT_ACCREDITATION_FAILED = "SUBMIT_ACCREDITATION_FAILED";
export interface SubmitAccreditationFailed extends Action {
  type: typeof SUBMIT_ACCREDITATION_FAILED;
}

export const RESET_SUBMITTED_STATE = "RESET_SUBMITTED_STATE";
export interface ResetSubmittedState extends Action {
  type: typeof RESET_SUBMITTED_STATE;
}

export type AccreditationFormActions =
  | SubmitAccreditation
  | SubmitAccreditationSuccess
  | SubmitAccreditationFailed
  | ResetSubmittedState;
