import {
  AccreditationFormActions,
  SUBMIT_ACCREDITATION_FAILED,
  SUBMIT_ACCREDITATION_SUCCESS,
  SUBMIT_ACCREDITATION,
  RESET_SUBMITTED_STATE,
} from "../actions/types/accreditationForm";
import { AccreditationFormState } from "../types/AccreditationFormState";

const accreditationFormState: AccreditationFormState = {
  isAccreditationSubmitted: false,
  isFormLoading: false,
};

export const reducer = (
  state: AccreditationFormState = accreditationFormState,
  action: AccreditationFormActions
): AccreditationFormState => {
  switch (action.type) {
    case SUBMIT_ACCREDITATION: {
      return {
        ...state,
        isFormLoading: true,
      };
    }
    case SUBMIT_ACCREDITATION_SUCCESS: {
      return {
        ...state,
        isFormLoading: false,
        isAccreditationSubmitted: true,
      };
    }
    case SUBMIT_ACCREDITATION_FAILED: {
      return {
        ...state,
        isFormLoading: false,
      };
    }
    case RESET_SUBMITTED_STATE: {
      return {
        ...state,
        isAccreditationSubmitted: false,
      };
    }

    default: {
      return state;
    }
  }
};
