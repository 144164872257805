import APIService from "@quest-finance/quest-fe-shared/dist/common/services/APIService";
import { SingleResponse } from "@quest-finance/quest-fe-shared/dist/common/types/SingleResponse";
import { AccreditationRequest } from "../types/AccreditationRequest";

class AccreditationService {
  static async submitAccreditation(
    data: AccreditationRequest
  ): Promise<SingleResponse<string> | undefined> {
    return APIService.jsonRequest<SingleResponse<string>, AccreditationRequest>(
      {
        method: "POST",
        path: "/accreditation/accreditations",
        data,
      }
    );
  }
}

export default AccreditationService;
